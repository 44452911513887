.video__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 40px 40px 40px;
  width: calc(100% - 80px);
  max-width: 1200px;
}

.video__container > iframe {
  width: 100%;
  aspect-ratio: 16/9;
}

@media screen and (max-width: 500px) {
  .video__container {
    width: 100%;
    padding: 0;
  }

  .video__title {
    padding: 0 20px;
  }
  .video__text {
    padding: 0 20px;
    margin-bottom: 30px;
  }
}
