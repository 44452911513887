.parlamentares__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  align-content: center;
  justify-content: center;
}

.parlamentares__container-federal {
  grid-template-columns: 1fr 1fr !important;
}

.parlamentares__container-federal > .parlamentar__wrapper:first-child {
  margin-right: 20px;
}

.parlamentares__container-federal > .parlamentar__wrapper:last-child {
  margin-left: 20px;
}

.parlamentares__container-municipal {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
}

.parlamentares__container-municipal > .parlamentar__wrapper {
  margin: 20px 20px !important;
}

.parlamentar__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: clamp(180px, 100%, 300px);
  margin: 20px 20px;
  margin-left: auto;
  margin-right: auto;
}

.parlamentar__picture {
  width: calc(100% - 12px);
  aspect-ratio: 1/1;

  border-radius: 50%;
  border: 6px solid var(--offwhite);
  margin-bottom: 30px;
}

.parlamentar__header {
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  bottom: 50px;

  width: fit-content;
  background-color: var(--offwhite);
}

.parlamentar__name {
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--theme-secondary);

  padding: 8px 40px 0 40px;
  white-space: nowrap;
}

.parlamentar__type {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  width: calc(100% - 80px);

  margin-top: -4px;
  margin-bottom: 8px;

  font-size: 0.9rem;
  font-weight: 500;
  color: var(--theme-secondary);
}

.parlamentar__socials {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  bottom: 0;
}

.parlamentar__socials > a > .social-icon__wrapper {
  background-color: var(--offwhite) !important;
  color: var(--theme-secondary);
}

.parlamentar-bio-section-wrapper {
  max-width: 1200px;
}

@media screen and (max-width: 1200px) {
  .parlamentar-bio-section-wrapper {
    flex-direction: column !important;
  }

  .bio-parlamentar__container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 1400px) {
  .parlamentares__container {
    grid-template-columns: 1fr 1fr;
  }

  .parlamentares__container > .parlamentar__wrapper:nth-child(odd) {
    margin-right: 20px;
  }

  .parlamentares__container > .parlamentar__wrapper:nth-child(even) {
    margin-left: 20px;
  }
}

@media screen and (max-width: 800px) {
  .parlamentares__container {
    grid-template-columns: 1fr;
  }

  .parlamentares__container-federal {
    grid-template-columns: 1fr !important;
  }

  .parlamentares__container-federal > .parlamentar__wrapper:first-child {
    margin-right: auto;
    margin-left: auto;
  }

  .parlamentares__container-federal > .parlamentar__wrapper:last-child {
    margin-right: auto;
    margin-left: auto;
  }

  .parlamentares__container > .parlamentar__wrapper:nth-child(odd) {
    margin-right: auto;
    margin-left: auto;
  }

  .parlamentares__container > .parlamentar__wrapper:nth-child(even) {
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 380px) {
  .parlamentar__name {
    font-size: 1.2rem;
    padding: 8px 20px 0 20px;
  }

  .parlamentar__type {
    font-size: 0.8rem;
    width: calc(100% - 40px);
  }

  .parlamentar__wrapper {
    margin-left: 0;
    margin-right: 0;
  }
}
