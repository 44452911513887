.social-icon__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  background-color: var(--social-icon-bg);

  margin-left: 5px;
  margin-right: 5px;

  border-radius: 50%;

  font-size: 1.4rem;
  color: var(--offwhite);

  transition: 0.2s ease-in-out;
}

.social-icon__wrapper:hover {
  cursor: pointer;

  transform: scale(1.1);
  opacity: 0.9;

  transition: 0.2s ease-in-out;
}

.collapsed-menu__social > a > .social-icon__wrapper {
  margin-left: 5px;
  margin-right: 5px;

  border-radius: 50%;

  font-size: 1.4rem;

  color: var(--navbar-collapsed-bg-color);
  background-color: var(--offwhite);

  transition: 0.2s ease-in-out;
}
