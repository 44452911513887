.section__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
}

.section__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: calc(100% - 80px);
  padding: 40px;
  max-width: 1600px;
}

.section__wrapper-hero {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 600px;
}

.section__container-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: calc(100% - 80px);
  padding: 40px;
  max-width: 1600px;
}

@media screen and (max-width: 700px) {
  .section__wrapper-hero {
    min-height: 100vw;
  }
}