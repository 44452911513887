html {
  display: flex;
  flex-direction: row;

  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: fit-content;

  background: #ffffff;
}

body {
  display: flex;
  flex-direction: row;

  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  background: #ffffff;
}

#root {
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: 100%;
}

/* Application Wrapper CSS */
.app__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weight);
  color: var(--main-text-color);

  overflow-x: hidden;
}

/* Unstyle Links */
a {
  text-decoration: none;
  color: var(--main-text-color);
}

a:visited {
  color: var(--main-text-color);
}

/* Basic Definitions */
.flex-r {
  display: flex;
  flex-direction: row;
}

.flex-c {
  display: flex;
  flex-direction: column;
}

.ss {
  align-items: flex-start;
  justify-content: flex-start;
}

.cc {
  align-items: center;
  justify-content: center;
}

.cs {
  align-items: start;
  justify-content: center;
}

.w-100 {
  width: 100%;
}

/* Text Classes */
.text-semibold {
  font-weight: 500;
}

.text-bold {
  font-weight: 700;
}

.text-muted {
  font-size: 9pt;
  font-weight: 100;
}

/* Padding Classes */
.p-4 {
  padding: 2.25rem !important;
}

/* Margin Classes */
.mt-0 {
  margin-top: 0 !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 2.25rem !important;
}

p {
  margin-bottom: 5px;
  line-height: 1.4rem;
}

strong {
  color: var(--theme-secondary);
}

.footer__info-entry > strong {
  color: var(--offwhite);
}

a {
  font-weight: 500;
  color: var(--theme-secondary);
  transition: 0.2s ease-in-out;
}

a:hover {
  font-weight: 700;
  transition: 0.2s ease-in-out;
}

h1 {
  margin: 0 0 30px 0;
  font-family: var(--var-font);
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;

  color: var(--title-color);
}

h2 {
}

h3 {
  margin: 30px 0 -5px 0;
  font-family: var(--var-font);
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 700;
  text-align: center;

  color: var(--title-color);
}

h4 {
}

.text__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  max-width: 120ch;
}

.pgm-link {
  text-decoration: none;
  color: var(--theme-secondary);
}

.pgm-link:hover {
  transform: scale(1.1);
  opacity: 0.9;
  transition: 0.2s ease-in-out;
}

.pgm-btn {
  display: flex;
  padding: 8px 30px;
  background-color: var(--offwhite);
  margin-top: 30px;
  border-radius: 8px;
}

/* Font */
@font-face {
  font-family: "Brandon";
  src: url("../../font/Brandon_thin.otf");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Brandon";
  src: url("../../font/Brandon_reg.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Brandon";
  src: url("../../font/Brandon_med.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Brandon";
  src: url("../../font/Brandon_bld.otf");
  font-weight: 700;
  font-style: normal;
}
