.filiacao-home__wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  max-width: 1000px;
}

.filiacao-home__image {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 50%;
  margin-right: 40px;
}

.filiacao-home__image > img {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  aspect-ratio: 16/9;
  background-color: rgb(211, 211, 211, 0.3);
}

.filiacao-home__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 50%;
}

.filiacao-home__text {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.4rem;
}

.filiacao-home__content > a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-top: 20px;
  background-color: var(--theme-secondary);
  color: var(--offwhite);
  font-weight: 600;

  padding: 10px 30px;
  border-radius: 6px;
  transition: 0.2s ease-in-out;
}

.filiacao-home__content > a:hover {
  background-color: var(--theme-secondary-dark);
  transform: scale(1.05);
  opacity: 0.9;
  transition: 0.2s ease-in-out;
}

.filiacao-home__content > a:visited {
  color: var(--offwhite);
}

@media screen and (max-width: 800px) {
  .filiacao-home__wrapper {
    flex-direction: column;
  }

  .filiacao-home__image {
    width: 100%;
    margin-right: 0;
  }

  .filiacao-home__content {
    margin-top: 20px;
    width: 100%;
    align-items: center;
    text-align: center;
  }
}
