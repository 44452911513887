.landing--form-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 293px;
  height: 40px;

  margin: 5px 5px;

  border-radius: 6px;
  border: 2px solid var(--theme-brown);

  color: rgba(255, 255, 255, 1);
  background-color: #ffffff;
}

.landing--form-item:last-child:nth-child(odd) {
  max-width: 600px !important;
}

.landing--form-item-textarea {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 600px;

  margin: 5px 5px;

  border-radius: 6px;
  border: 2px solid var(--theme-brown);

  color: rgba(255, 255, 255, 1);
  background-color: #ffffff;
}

.landing--form-item > input {
  border: none;
  outline: none;

  min-width: 250px;
  width: 100%;
  height: 30px;

  margin-left: 20px;
  margin-right: 20px;

  font-family: var(--main-font);
  font-size: 0.9rem;
  font-weight: 500;

  color: var(--theme-brown);
  background-color: rgba(0, 0, 0, 0);
}

.landing--form-item > input::placeholder {
  color: var(--theme-brown);
}

.landing--form-item > input::-ms-input-placeholder {
  color: var(--theme-brown);
}

.landing--form-item > input:-ms-input-placeholder {
  color: var(--theme-brown);
}

.landing--form-item > select {
  -webkit-appearance: button;
  -moz-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-padding-end: 20px;
  -moz-padding-end: 20px;
  -webkit-padding-start: 2px;
  -moz-padding-start: 2px;

  background-color: var(--theme-light-hover);

  border: 0 transparent;

  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  margin: 0 16px;

  min-width: 250px;
  width: 100%;
  outline: none;

  font-family: var(--main-font);
  font-size: 0.9rem;
  font-weight: 500;

  color: var(--theme-brown);
  background-color: rgba(0, 0, 0, 0);
}

.landing--form-item > select > option {
  outline: none;

  font-family: var(--main-font);
  font-weight: 500;
  font-size: 9pt;

  color: var(--theme-brown);
}

.landing--form-item > select > option:first-child {
  color: var(--theme-brown);
}

/* Submit Button */

.landing--submit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: fit-content;
  height: 40px;

  margin: 15px 0 15px 0;
  padding: 0 20px;

  border-radius: 6px;

  color: var(--offwhite);
  background-color: var(--theme-brown);
  font-size: 0.9rem !important;
}

.landing--submit > svg {
  margin-right: 8px;
}

.landing--submit:hover {
  cursor: pointer;
  opacity: 0.9;
  transform: scale(1.05);
  transition: ease-in-out 0.1s;
}

.landing--submit:hover > svg {
  animation: pulse 1s infinite;
}

.landing--submit:active {
  cursor: pointer;
  opacity: 0.8;
}

.landing--submit > span {
  margin: 0 6px;

  font-size: 0.9rem;
  font-weight: 500;
}

.legal-notice {
  color: var(--theme-brown) !important;
}

/* Checkbox */

.checkbox-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-option {
  margin-right: 20px;

  height: fit-content;
}

.optin-recaptcha {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin: 15px 15px;
}

.recaptcha-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  position: relative;

  user-select: none;

  padding-top: 3px;
  padding-left: 38px;

  font-weight: 500;
  font-size: 0.9rem;
  line-height: 20pt;
}

.form-optin > a {
  font-weight: 700;
}

.form-optin > a:hover {
  color: var(--theme-secondary-dark);
}

.checkbox-container > input {
  visibility: hidden;
  width: 0;
  height: 0;
  opacity: 0;
}

.checkbox-container:hover {
  cursor: pointer;
}

.checkbox-container:hover > input ~ .checkmark {
  background: #ffffff;
  border: 2px solid var(--theme-secondary-dark);
  opacity: 0.9;
}

.checkbox-container > input:checked ~ .checkmark {
  background: #ffffff;
}

.checkbox-container > input:not(:checked) ~ .checkmark > .checkmark-icon {
  visibility: hidden;
}

.checkbox-container:hover > input:checked ~ .checkmark > .checkmark-icon {
  animation: pulse 1s infinite;
}

.checkmark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;

  border-radius: 8px;
  height: 30px;
  width: 30px;

  font-size: 11pt;

  color: var(--theme-secondary);
  background: #ffffff;
  border: 2px solid var(--theme-secondary);
}

.form--alert-msg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border: 1px solid var(--alert-color);
  background-color: var(--alert-bg);
  color: var(--alert-color);

  font-weight: 700;
  padding: 8px 30px;
  border-radius: 6px;
}

/* Textarea */
textarea {
  resize: none;
  font-family: Arial, Helvetica, sans-serif;

  border: none;
  outline: none;

  min-width: 250px;
  width: 100%;
  min-height: 100px;

  margin-left: 20px;
  margin-right: 20px;

  font-family: var(--main-font);
  font-size: 0.9rem;
  font-weight: 500;

  padding: 10px 0;

  color: var(--theme-brown);
  background-color: rgba(0, 0, 0, 0);
}

@media screen and (min-width: 501px) and (max-width: 899px) {
  .landing--form-item > input {
    min-width: 150px;
  }
  .landing--form-item > select {
    min-width: 150px;
  }
}

@media only screen and (max-width: 500px) {
  .landing--form-item > input {
    min-width: 150px;
  }
  .landing--form-item > select {
    min-width: 150px;
  }

  .landing--form-item-textarea {
    min-width: 210px;
  }

  .landing--form-item-textarea {
    width: calc(100% - 14px);
    max-width: 600px;

    margin: 5px 5px;

    border-radius: 6px;
    border: 2px solid var(--theme-brown);

    color: rgba(255, 255, 255, 1);
    background-color: #ffffff;
  }
}

/* pulse animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
