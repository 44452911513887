/* Subscribe Footer */
.subs-footer__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: clamp(350px, 100%, 100%);
  background-color: white;
}

.subs-footer__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.subs-footer__picture {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: clamp(250px, 40%, 700px);
  height: clamp(300px, 100%, 700px);

  background-size: cover;
  background-position: 50% 50%;
}

.subs-footer__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1;

  width: 60%;
  height: fit-content;
}

.subs-footer__form-title {
  font-size: 1.8rem;
  font-weight: 700;

  margin-top: 40px;
  color: var(--theme-brown);
  font-family: var(--var-font);
  text-transform: uppercase;
}

.subs-footer__form-text {
  margin-top: 30px;
  font-weight: 600;

  max-width: 800px;
  text-align: center;
  padding: 0 40px;
}

/* Footer */
.footer__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
}

.footer__social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  background-color: var(--footer-bg-color);
}

.footer__social-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 15px 30px;
}

.footer__social-container > a > .social-icon__wrapper {
  background-color: var(--footer-color);
  color: var(--footer-bg-color);
}

.footer__divider {
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 2px;

  background-color: var(--footer-color);
}

.footer__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background-color: var(--footer-bg-color);
}

.footer__content-container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 20px;

  width: clamp(280px, 100%, 1600px);
  height: 100%;

  padding: 50px 30px;
}

.footer-cand__content-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;

  width: clamp(280px, 100%, 1600px);
  height: 100%;

  padding: 50px 30px;
}

.footer_menu-info-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.footer__logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.footer__col {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.footer__col > a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.footer__col > a > img {
  width: clamp(150px, 100%, 200px);
  margin: 15px 20px;
}

.footer__logo > img {
  width: clamp(150px, 100%, 200px);
  margin: 0 20px;
}

.footer__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin-bottom: auto;
  margin-left: 20px;
  margin-right: 20px;

  color: var(--footer-color);
}

.footer__info-title {
  font-size: 0.9rem;
  font-family: var(--var-font);
  font-weight: 700;
  text-transform: uppercase;
}

.footer__info-subtitle {
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.footer__info-entry {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  font-size: 0.9rem;
  margin-bottom: 8px;
}

.footer__info-icon {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 1.2rem;

  min-width: 30px;
}

.footer__info-entry-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.footer__menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  flex: 1 1;
  margin-bottom: auto;

  color: var(--footer-color);
}

.footer__support {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer__support > a {
  width: clamp(150px, 100%, 200px);
}

.footer__support > a > img {
  width: clamp(150px, 100%, 200px);
  margin: 15px 20px;
}

/* SubFooter*/
.subfooter__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 45px;
  background-color: var(--subfooter-bg-color);
}

.subfooter__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: clamp(200px, 100%, 1600px);
  margin: 0 20px;

  color: var(--subfooter-color);

  font-size: 0.75rem;
  font-weight: 600;

  transition: 0.2s ease-in-out;
}

.subfooter__legal {
  text-align: center;
  line-height: 1.3rem;
}

.subfooter__license-credits {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  flex: 1;

  transition: 0.2s ease-in-out;
}

.subfooter__license {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  flex: 1;

  font-size: 1.4rem;

  padding-right: 5px;
  border-right: 1px solid var(--subfooter-color);
  margin-right: 8px;
}

.subfooter__license > svg {
  margin: 0 4px;
}

.subfooter__credits > span > a {
  text-decoration: none;
  color: var(--subfooter-color);

  font-weight: 700 !important;
  transition: 0.2s ease-in-out;
}

.subfooter__credits > span > a:hover {
  font-weight: 800 !important;
  transition: 0.2s ease-in-out;
}

@media screen and (max-width: 900px) {
  .footer__content-container {
    grid-template-columns: 1fr;
  }
  .footer-cand__content-container {
    grid-template-columns: 1fr 1fr;
  }

  .footer_menu-info-wrap {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
  }
}

@media screen and (max-width: 900px) {
  .subs-footer__container {
    flex-direction: column;
  }

  .subs-footer__picture {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-width: 250px;
    width: 100%;
    max-width: 100%;

    min-height: 400px;
    max-height: 5vh;

    background-size: cover;
    background-position: 50% 50%;
  }

  .subs-footer__form {
    width: calc(100% - 60px);
    height: fit-content;

    padding: 30px;
  }

  .footer__support {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .footer__support > a {
    width: clamp(150px, 100%, 200px);
    margin: 40px 20px 0 20px;
  }

  .footer__support > a > img {
    width: clamp(150px, 100%, 200px);
    margin: 15px 0 0 0;
  }

  /* SubFooter */
  .subfooter__container {
    flex-direction: column;

    width: clamp(200px, 100%, 1600px);
    margin: 20px;
    transition: 0.2s ease-in-out;
  }

  .subfooter__license-credits {
    margin-top: 10px;

    transition: 0.2s ease-in-out;
  }

  .footer__menu > .footerlink__a {
    margin-right: 0;
  }

  .footer__menu {
    min-width: 250px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .footer__info {
    align-items: center;
    justify-content: center;
    text-align: center;

    margin-top: 40px;
  }
}

@media screen and (max-width: 600px) {
  .subs-footer__form-title {
    margin-top: 20px;
  }

  .subs-footer__form-text {
    margin-bottom: 15px;
    padding: 0 20px;
  }

  .footer-cand__content-container {
    grid-template-columns: 1fr;
  }

  .footer__menu {
    min-width: 250px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .footer__menu > .footerlink__a {
    margin-right: 0;
  }

  .footer__info {
    align-items: center;
    justify-content: center;
    text-align: center;

    margin-top: 40px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1180px) {
  .subs-footer__picture {
    min-height: 620px;
  }
}
