.footerlink__a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 8px;
  margin-right: 40px;

  font-family: var(--var-font);
  text-transform: uppercase;

  width: clamp(200px, fit-content, 300px);
}

.footerlink__a:hover > .footerlink__underscore {
  cursor: pointer;

  width: calc(100% - 40px);
  border-bottom: 2px solid var(--social-icon-bg);

  transition: 0.2s ease-in-out;
}

.footerlink__a:visited {
  color: var(--offwhite);
}

.footerlink__underscore {
  width: 0;
  margin: 0 20px;
  border-bottom: 2px solid transparent;

  transition: 0.2s ease-in-out;
}

.footerlink__a:hover > .footerlink__wrapper {
  font-weight: 700;
}

.footerlink__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 20px;

  font-size: 1rem;
  font-weight: 600;

  line-height: 1.5rem;

  white-space: nowrap;
}

.footerlink__wrapper:last-child {
  margin-right: 0;
}

.footerlink__featured > .footerlink__wrapper:last-child {
  margin-right: 20px;
}

.footerlink__featured {
  background-color: var(--social-icon-bg);

  padding: 5px 0px;
  margin-left: 40px;
  margin-right: 0;

  border-radius: 6px;

  color: var(--offwhite) !important;
}