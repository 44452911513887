.navlink__a {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  font-family: "Brandon", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  color: var(--theme-secondary);
}

.navlink__menu-sub {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  top: 30px;
  opacity: 0%;
  visibility: hidden;
  transition: 0.2s ease-in-out;

  z-index: 1001;
}

.navlink__menu-sub-spacer {
  display: flex;
  height: 20px;
  background-color: transparent !important;
}

.navlink__menu-sub-bg {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;

  background-color: var(--offwhite);
}

.navlink__submenu {
  display: flex;
  background-color: var(--offwhite);
  padding: 4px 0;
  width: fit-content;
}

.navlink__a:hover > .navlink__menu-sub {
  display: flex;
  visibility: visible;
  opacity: 100%;

  transition: 0.2s ease-in-out;
}

.navlink__a:visited {
  color: var(--theme-secondary);
}

.navlink__a:hover > .navlink__underscore {
  cursor: pointer;

  width: calc(100% - 40px);
  border-bottom: 2px solid var(--social-icon-bg);

  transition: 0.2s ease-in-out;
}

.navlink__underscore {
  width: 0;
  margin: 0 20px;
  border-bottom: 2px solid transparent;

  transition: 0.2s ease-in-out;
}

.navlink__a:hover > .navlink__wrapper {
  font-weight: 700;
}

.navlink__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 20px;

  font-size: 1rem;
  font-weight: 600;

  line-height: 1.5rem;

  white-space: nowrap;
}

.navlink__wrapper:last-child {
  margin-right: 0;
}

.navlink__featured > .navlink__wrapper:last-child {
  margin-right: 20px;
}

.navlink__featured {
  background-color: var(--social-icon-bg);

  padding: 5px 0px;
  margin-left: 40px;
  margin-right: 0;

  border-radius: 6px;

  color: var(--offwhite) !important;
}

.collapsed-menu__menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.collapsed-menu__menu > a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.collapsed-menu__menu > a > .navlink__menu-sub {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  max-height: 0px !important;
  top: 0px;
  height: 100%;
  opacity: 100%;
  overflow: hidden;

  margin-bottom: 10px;

  transition: 0.5s ease-in-out !important;
}


.navlink__menu-sub-spacer {
  display: flex;
  height: 20px;
  background-color: transparent !important;
}

.collapsed-menu__menu > a:hover > .navlink__menu-sub {
  max-height: 100vh !important;
  height: 100%;
  transition: 0.5s ease-in-out !important;
}

.collapsed-menu__menu > a > .navlink__menu-sub > .navlink__menu-sub-spacer {
  height: 0;
}

.collapsed-menu__menu > .navlink__a {
  color: #ffffff !important;
}

.collapsed-menu__menu > .navlink__a > .navlink__wrapper {
  margin: 5px 0 !important;
}

.collapsed-menu__menu > .navlink__featured {
  margin: 5px 0 !important;
  padding: 0 20px;
  background-color: var(--offwhite);
  color: var(--mobile-navbar-color) !important;
}
