.parlamentar-bio-section-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-bottom: 60px;
}

.parlamentar-bio-section-wrapper:nth-child(even) {
  flex-direction: row-reverse;
}

.bio-parlamentar__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  max-width: 75ch;
  margin-left: 40px;

  line-height: 1.5rem;
}

.bio-parlamentar__container > h1 {
  margin-bottom: 0 !important;
}

.parlamentar-bio-section-wrapper:nth-child(even) > .bio-parlamentar__container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  margin-left: 0;
  margin-right: 40px;
}
