body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.w-50 {
  width: 50%;
}

.text-c {
  text-align: center;
}

.mw-800 {
  max-width: 800px;
}

.agindo-juntos-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 50px;
  padding: 0 20px;

  margin-top: 15px;
  margin-bottom: -2rem;

  border-radius: 25px;

  background: black;
  color: yellow;

  transition: 0.2s ease-in-out;
  font-size: 1rem;
}

.agindo-juntos-button:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: 0.2s ease-in-out;
}

.finance__wrapper {
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.finance__container {
  width: calc(100% - 120px);
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.financiamento-coletivo {
  width: calc(100%-2rem);
  padding: 2rem;
  background: white;
  border-radius: 50px;
}

@media screen and (min-width: 501px) and (max-width: 899px) {
  .finance__container {
    width: calc(100% - 2rem);
    margin: 0 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .financiamento-coletivo {
    width: calc(100%-0.5rem);
    padding: 0.5rem;
    background: white;
    border-radius: 50px;
  }
}


@media screen and (max-width: 500px) {
  .finance__container {
    width: 100%;
    margin: 0 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .financiamento-coletivo {
    width: calc(100%-0.5rem);
    padding: 0.5rem;
    background: white;
    border-radius: 0;
  }
}
