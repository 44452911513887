.eleicoes__grid {
  display: grid;
  grid-template-columns: 250px 250px 250px 250px 250px;
  grid-gap: 40px;
}

.eleicoes__grid > a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;
}

.eleicoes__grid > a:hover {
  cursor: pointer;
  transform: scale(1.1);
  opacity: 0.9;
  transition: 0.3s ease-in-out;
}

.candidato-snippet-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.candidato-snippet-foto > img {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  aspect-ratio: 1/1;

  max-width: 180px;
  border: 5px solid var(--offwhite);
}

.candidato-snippet-info {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  bottom: 10px;

  padding: 8px 20px;
  border-radius: 6px;
  background-color: var(--offwhite);
}

.candidato-snippet-name {
  font-size: 0.9rem;
  font-weight: 700;

  white-space: nowrap;
}

.candidato-snippet-type {
  font-size: 0.75rem;
  white-space: nowrap;
}

.vereadores-city-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  flex-wrap: wrap;
}

.vereadores-city-link > a {
  text-decoration: none;
  color: var(--offwhite);
  transition: 0.2s ease-in-out;
}

.vereadores-city-link > a:hover {
  transform: scale(1.1);
  opacity: 0.9;
  transition: 0.2s ease-in-out;
}

.city-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 8px 30px;
  margin: 8px;
  background-color: var(--theme-secondary);
  border-radius: 6px;
}

@media screen and (max-width: 1500px) {
  .eleicoes__grid {
    grid-template-columns: 250px 250px 250px 250px;
    grid-gap: 40px;
  }
}

@media screen and (max-width: 1200px) {
  .eleicoes__grid {
    grid-template-columns: 250px 250px 250px;
    grid-gap: 40px;
  }
}

@media screen and (max-width: 900px) {
  .eleicoes__grid {
    grid-template-columns: 250px 250px;
    grid-gap: 40px;
  }
}

@media screen and (max-width: 600px) {
  .eleicoes__grid {
    grid-template-columns: 250px;
    grid-gap: 40px;
  }
}
