.notfound__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  flex: 1;

  background-color: var(--offwhite);
}

.notfound__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: calc(100% - 80px);
  padding: 40px;
  max-width: 600px;
}

.notfound-oops {
  font-size: 3.5rem;
  font-weight: 800;

  color: var(--theme-secondary);
}

.notfound-subtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;

  font-size: 1rem;
  font-weight: 900;

  margin-top: 20px;
  margin-bottom: 40px;
  max-width: 400px;

  font-family: var(--var-font);
  text-transform: uppercase;
  color: var(--theme-secondary);
}

.notfound-text {
  text-align: center;
  max-width: 600px;

  font-weight: 600;
  line-height: 1.6rem;

  margin-bottom: 40px;
}

.notfound-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 10px 20px;

  background-color: var(--theme-primary);
  border-radius: 8px;

  font-weight: 700;
  color: var(--theme-secondary);
  transition: 0.2s ease-in-out;
}

.notfound-btn:hover {
  cursor: pointer;
  transform: scale(1.1);
  opacity: 90%;
  transition: 0.2s ease-in-out;
}

.notfound-btn-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
