.landing__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 0px solid #f4f4f4;

  min-width: 250px;
  max-width: 700px;
  min-height: 300px;
  transition: ease-in-out 0.1s;

  color: var(--theme-brown);
}

.landing__form--subgroup--wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  flex-wrap: wrap;

  width: 100%;
  height: 100%;
}

.landing__form--subgroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.animate-pulse {
  animation: pulse 1s infinite;
}

.form--alert-msg {
  margin: 10px 0 5px 0;
  font-size: 10pt;
}

.form-title {
  font-size: 1.2rem;
  font-weight: 700;
}

.post-form {
  font-size: 1.2rem;
  font-weight: 700;
}

.pf-small {
  font-size: 1.1rem;
  font-weight: 500 !important;
}

.link_post_form:hover {
  font-weight: 700;
}

.legal-notice {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-top: 10px;

  text-align: center;
  font-size: 9pt;

  max-width: 350px;

  color: #f4f4f4;
}

@media screen and (min-width: 501px) and (max-width: 899px) {
  .landing__wrapper {
    min-width: 100%;
    min-height: 100%;

    width: fit-content;
    height: fit-content;
  }

  .landing__container {
    flex-direction: column;
    justify-content: flex-start;

    max-height: unset;
    margin: 0;
  }

  .landing__section {
    height: fit-content;
    width: calc(100% - 80px);
    padding: 30px;
  }

  .landing__form {
    max-width: 650px;
    width: calc(100% - 60px);

    min-height: 150px;
    min-width: 200px;

    padding: 30px;

    margin-bottom: 20px;
  }

  .landing__form--subgroup--wrapper {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
  }

  .landing__form--subgroup {
    height: 100%;

    margin: 0 5px;
  }

  .checkbox-option {
    margin-right: 0 !important;
  }
}

@media only screen and (max-width: 500px) {
  .landing__wrapper {
    min-width: 100%;
    min-height: 100%;

    width: fit-content;
    height: fit-content;
  }

  .landing__container {
    flex-direction: column;
    justify-content: flex-start;

    min-height: 100%;
    max-height: unset;
    margin: 0;
  }

  .landing__section {
    height: fit-content;
    width: calc(100% - 40px);
    padding: 20px;
  }

  .landing__form {
    height: fit-content;
    width: calc(100%);
    margin: 20px 0;

    min-height: 150px;
    min-width: 200px;

    margin-top: 20px;
    margin-bottom: 20px;
  }

  .landing--text > p {
    font-size: 10pt;
    font-weight: 500;
    line-height: 17pt;

    text-align: center;

    color: var(--theme-white-100);
  }

  .checkbox-option {
    margin-right: 0 !important;
  }

  .checkbox-container > span:nth-child(1) {
    display: flex;
    width: fit-content !important;
    padding: 0;
  }
}

/* pulse animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
