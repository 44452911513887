.theme__main {
  --offwhite: #f4f4f4;

  --navbar-color: #ffe600;
  --mobile-navbar-color: #9933cc;

  --theme-primary: #ffcc00;
  --theme-secondary: #550080;
  --theme-secondary-dark: #34004e;

  --theme-brown: var(--theme-secondary);

  --alert-color: #ea4848;
  --alert-bg: #ffe1e1;

  /* Generics */
  --social-icon-bg: var(--theme-secondary);
  --social-icon-hover-bg: var(--theme-secondary-dark);

  --navbar-bg-color: var(--theme-primary);
  --navbar-color: var(--theme-secondary);
  --navbar-collapsed-bg-color: var(--theme-secondary);
  --navbar-collapsed-color: var(var(--offwhite));

  --footer-bg-color: var(--theme-secondary);
  --footer-color: var(--offwhite);

  --subfooter-bg-color: var(--theme-secondary-dark);
  --subfooter-color: var(--offwhite);



  --title-color: var(--theme-secondary);
  --var-font: "Brandon", Arial sans-serif;
}
