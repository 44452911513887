.navbar-cand__wrapper {
  position: fixed;
  top: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100px;

  background-color: var(--theme-secondary);
  color: var(--offwhite);
  transition: 0.2s ease;

  z-index: 500;
}

.header__whitespace {
  min-height: 100px;
}

.navbar-cand__container {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: calc(100% - 40px);
  height: calc(100% - 40px);

  padding: 20px 20px;

  max-width: 1600px;
}

.navbar-cand__name-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;

  margin-right: 15px;
  text-align: right;
}

.navbar-cand__name {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.8rem;
  white-space: nowrap;
}

.navbar-cand__type {
  font-size: 0.9rem;
  font-weight: 500;
  white-space: nowrap;
}

.navbar-cand__divider {
  border-right: 3px solid var(--offwhite);
  margin-top: 6px;
  height: 40px;
}

.navbar-cand__number {
  line-height: 2.2rem;
  font-size: 3rem;
  font-weight: 700;
  margin-left: 15px;
}

.navbar-cand__logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 100%;
}

.navbar-cand__logo > img {
  min-height: 50px;
  max-height: 50px;
  height: 100%;
}

.navbar-cand__menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  flex: 1;

  padding: 0;

  transition: 0.2s ease-in-out;
}

.navbar-cand__menu > a {
  color: var(--offwhite);
}

.navbar-cand__menu > a:visited {
  color: var(--offwhite);
}

.navbar-cand__menu > a:hover > .navlink__underscore {
  border: 1px solid var(--offwhite);
}

.navbar-cand__social {
  position: absolute;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: fit-content;
  right: 20px;
  top: 75px;

  transition: 0.2s ease-in-out;
}

.navbar-cand__social > a > .social-icon__wrapper {
  background-color: var(--offwhite);
  color: var(--theme-secondary);
}

.navbar-cand__collapsed-icon {
  display: none;
}

.collapsed-menu__wrapper {
  position: fixed;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  height: fit-content;

  right: 0;

  z-index: 1500;
  transition: 0.2s ease-in-out;

  background-color: var(--navbar-collapsed-bg-color);
  color: var(--offwhite);

  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.collapsed-menu__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: auto 0;

  width: calc(100% - 40px);
  height: calc(100% - 40px);

  padding: 20px;
}

.collapsed-menu__logo {
  display: flex;
  flex-direction: row;
  height: fit-content;
}

.collapsed-menu__logo > img {
  width: clamp(200px, 80vw, 400px);
  max-height: 200px;
  margin-bottom: 40px;
}

.collapsed-menu__social {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  margin-top: 40px;

  width: 100%;
}

.collapsed-menu__close {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 30px;

  font-size: 1.8rem;
  transition: 0.2s ease-in-out;
}

.collapsed-menu__close:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.2s ease-in-out;
}

.menu-hide {
  width: 100%;
  right: -100vw;
  z-index: -1 !important;

  transition: 0.4s ease-in-out;
}

@media screen and (max-width: 900px) {
  .navbar-cand__menu {
    display: none;
    transition: 0.2s ease-in-out;
  }

  .navbar-cand__social {
    position: relative;
    justify-content: flex-end;

    width: 100%;
    right: unset;
    top: unset;

    transition: 0.2s ease-in-out;
  }

  .navbar-cand__collapsed-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    flex: 1;

    padding: 10px 10px 10px 40px;

    font-size: 1.8rem;
  }
}

@media screen and (max-width: 700px) {
  .navbar-cand__wrapper {
    min-height: 120px;
    transition: 0.2s ease;
    align-items: center;
    justify-content: center;
  }

  .navbar-cand__container {
    align-items: center;
    justify-content: center;
  }

  .header-cand__whitespace {
    min-height: 120px;
  }

  .navbar-cand__social {
    position: absolute;
    justify-content: center;

    width: 100%;
    right: 0px;
    top: 85px;

    transition: 0.2s ease-in-out;
  }
}

@media screen and (max-width: 450px) {
  .navbar-cand__name {
    display: flex;
    font-size: 1.3rem;
    white-space: unset;
  }

  .navbar-cand__type {
    visibility: hidden;
    display: none;
  }

  .navbar-cand__divider {
    border-right: 3px solid var(--offwhite);
    margin-top: 6px;
    height: 40px;
  }

  .navbar-cand__number {
    font-size: 2.5rem;
  }
}
