.bio__wrapper {
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.bio__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  max-width: 1200px;
}

.bio__content {
  line-height: 1.5rem;
  font-weight: 400;
  columns: 2;
  gap: 40px;
}

@media screen and (max-width: 800px) {
  .bio__content {
    columns: 1;
    max-width: 75ch;
  }
}
