.financie__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: calc(100% - 80px);
  padding: 40px;
  max-width: 1120px;
  border-radius: 10px;
  background-color: #ffffff;
}

.financie__container > iframe {
  width: 100%;
}